<template>
  <main v-if="news && print">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link" :class="{ active: activeTab === 'news' }" @click="switchTab('news', $event)" id="newsTab" data-bs-toggle="tab" data-bs-target="#newsContent" type="button"
          role="tab" aria-controls="newsContent" aria-selected="true"><i class="bi-pen"></i> {{ news.name }} </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" :class="{ active: activeTab === 'mixin' }" @click="switchTab('mixin', $event)" id="mixinTab" data-bs-toggle="tab" data-bs-target="#mixinContent" type="button"
          role="tab" aria-controls="mixinContent" aria-selected="false"><i class="bi-puzzle"></i> Mixins</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" :class="{ active: activeTab === 'print' }" @click="switchTab('print', $event)" id="printTab" data-bs-toggle="tab" data-bs-target="#printContent" type="button"
          role="tab" aria-controls="printContent" aria-selected="false"><i class="b-printer"></i> Print</button>
      </li>
      <li v-if="user.can_send" class="nav-item" role="presentation">
        <button class="nav-link" :class="{ active: activeTab === 'send' }" @click="switchTab('send', $event)" id="sendTab" data-bs-toggle="tab" data-bs-target="#sendContent" type="button" role="tab"
          aria-controls="sendContent" aria-selected="false"><i class="bi-envelope"></i> Email</button>
      </li>
    </ul>

    <div class="tab-content mt-3">
      <div class="tab-pane fade show active" id="newsContent" role="tabpanel" aria-labelledby="newsTab">

        <!-- <button class="btn btn-secondary btn-sm ml-2 mb-2 float-end" type="button" :disabled="!canEdit || !dirty"
          @click="onSave()">
          Save
        </button> -->

        <div v-if="canEdit">
          <MdEditor class="text-left" v-model="markdown" language="en-US"
            :toolbarsExclude="['task', 'code', 'mermaid', 'katex', 'github', 'htmlPreview']" :theme="mdEditorTheme"
            @onSave="onMdEditorSave" @onChange="onMdEditorChange" @onUploadImg="handleImageUpload" />
        </div>
        <div v-else>
          <MdEditor class="text-left" :previewOnly="true" v-model="news.markdown" language="en-US"
            :toolbarsExclude="['task', 'code', 'mermaid', 'katex', 'github', 'htmlPreview']" :theme="mdEditorTheme" />
        </div>

        <div class="accordion mt-2 accordion-sm" id="newsAccordion">

          <div class="accordion-item">
            <h2 class="accordion-header" id="newsSettingsHeader">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#newsCollapseSettings" aria-expanded="false" aria-controls="newsCollapseSettings">
                <i class="bi bi-gear me-2"></i> Settings
              </button>
            </h2>
            <div id="newsCollapseSettings" class="accordion-collapse collapse" aria-labelledby="newsSettingsHeader"
              data-bs-parent="#newsAccordion">
              <div class="accordion-body">

                <div class="row row-cols-1 row-cols-md-12">
                  <div class="col-md-4">
                    <label>Name</label>
                    <input class="form-control form-control-sm" :disabled="!canEdit" v-model="news.name" @change="onSave">
                  </div>

                  <div class="col-md-4">
                    <label>Subject/title</label>
                    <input class="form-control form-control-sm" :disabled="!canEdit" v-model="news.subject" @change="onSave">
                  </div>

                  <div class="col-md-4">
                    <label>Placeholders <a target="_blank"
                        href="https://www.notion.so/8f6313ee3b714f329b214e27a0b40a9a?v=9eaed5aeb0e348f4b77ab01c1ae0b4cb&pvs=4"><i
                          class="bi bi-question-circle"></i></a></label>
                    <input id="placeholders-label" class="form-control form-control-sm" :disabled="!canEdit" type="text"
                      v-model="news.placeholdersString" :placeholder="todayDatePlaceholder()" @change="onSave">
                  </div>
                </div>

                <button v-if="news.locked"
                  :class="['btn', news.locked ? 'btn-warning' : 'btn-outline-secondary', 'btn-sm', 'my-3']" type="button"
                  :disabled="!canEdit" @click="onLock">
                  <i class="bi-lock"></i>
                  Locked by {{ news.locked.user.name }} &lt;{{ news.locked.user.email }}&gt;
                </button>
                <button v-else :disabled="!canEdit" class="btn btn-outline-secondary btn-sm ml-2 my-3" type="button"
                  @click="onLock">
                  <i class="bi-unlock"></i>
                  Anyone can edit
                </button>

                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <textarea class="form-control form-control-sm" :disabled="!canEdit" v-model="news.notes" rows="2"
                        placeholder=""></textarea>
                      <div class="form-text">Notes</div>
                    </div>
                  </div>
                </div>

                <!-- </div> -->

                <div class="card mt-2 border-danger p-3" v-if="canEdit">
                  <h4 class="text-danger">Danger zone</h4>
                  <div class="my-2">
                    Deleting is forever, to proceed enter <q>{{ news.id }}</q>
                  </div>
                  <form class="form-inline">
                    <input class="form-control mb-2" type="text" v-model="verifyCode" placeholder="Enter news id">
                  </form>

                  <button class="btn btn-danger" :disabled="verifyCode !== news.id"
                    @click="onDeleteNews()">Delete</button>
                </div>
              </div>

            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="newsTemplateHeader">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapse-news-template" aria-expanded="false" aria-controls="collapse-news-template">
                <i class="bi bi-palette-fill me-2"></i> Template
              </button>
            </h2>
            <div id="collapse-news-template" class="accordion-collapse collapse" aria-labelledby="newsTemplateHeader"
              data-bs-parent="#newsAccordion">
              <div class="accordion-body">
                <div class="mb-2">{{ news.template }} <a v-if="templateURL" target="_blank" :href="templateURL"><i
                      class="bi bi-box-arrow-up-right"></i></a> ({{ user.city_name }})</div>
              </div>
            </div>
          </div>

          <div v-if="user.role === 'admin'" class="accordion-item">
            <h2 class="accordion-header" id="newsEditHeader">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#news-collapse-edit" aria-expanded="false" aria-controls="news-collapse-edit">
                <i class="bi-code me-2"></i> Code (admin)
              </button>
            </h2>
            <div id="news-collapse-edit" class="accordion-collapse collapse" aria-labelledby="newsEditHeader"
              data-bs-parent="#newsAccordion">
              <div class="accordion-body">
                <div class="accordion-body">
                  <pre><code class="trace">{{ JSON.stringify(news, null, 2) }}</code></pre>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="tab-pane fade" id="mixinContent" role="tabpanel" aria-labelledby="mixinTab">
        <MixinBrowser :account_id="news.account_id" />
      </div>

      <div class="tab-pane fade" id="printContent" role="tabpanel" aria-labelledby="printTab">
        <!-- <div class="alert alert-warning" v-if="dirty">⚠️ You have unsaved changes in the News</div> -->
        <!-- @change="onPrintChanged" -->
        <PrintEditor v-model="print" :news="news"/>
      </div>

      <div v-if="user.can_send" class="tab-pane fade" id="sendContent" role="tabpanel" aria-labelledby="sendTab">
        <!-- <div class="alert alert-warning" v-if="dirty">⚠️ You have unsaved changes in the News</div> -->
        <!-- @change="onPrintChanged" -->
        <PrintMailer v-model="print" />
      </div>
    </div>
  </main>
</template>

<script setup>
import { api, authHeaders } from '../api';
import dayjs from 'dayjs';

import { ref, onMounted, onBeforeUnmount } from 'vue';

import gravatar from 'gravatar';

import MdEditor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
const mdEditorTheme = ref('light');

import MixinBrowser from '../components/MixinBrowser.vue';
import PrintEditor from '../components/PrintEditor.vue';
import PrintMailer from '../components/PrintMailer.vue';

const print = ref();
const news = ref();
const markdown = ref('');
const canEdit = ref(false);
const verifyCode = ref('');

const dirty = ref(false);
const activeTab = ref('news');

const templateURL = ref('');

import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();

import { useUserStore } from '../stores/user';
const userStore = useUserStore();
const user = userStore.user;

user.avatarUrl = gravatar.url(user.email, { protocol: 'https', s: '100', r: 'pg', d: 'retro' });

onMounted(async () => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.addEventListener('change', updateTheme);
  updateTheme(prefersDarkTheme);

  news.value = (await api.get('news/' + route.params.id, authHeaders())).data;
  markdown.value = news.value.markdown;

  print.value = (await api.get('prints/news/' + route.params.id, authHeaders())).data;

  document.title = news.value.name;
  canEdit.value = news.value.locked ? news.value.locked.user.email === user.email : true;
  templateURL.value = `${import.meta.env.VITE_API_URL}templates/${news.value.template}`;
});

const switchTab = (tabName, event) => {
  if (dirty.value) {
    console.log('dirty')
    if (tabName === 'print' || tabName === 'send') {
      if (confirm('There are unsaved changes. Do you want to save?')) {
        onSave();
        activeTab.value = tabName;
      } else {
        activeTab.value = tabName;
        //event.preventDefault(); // Prevent switching to the new tab
      }
    }
  }

  activeTab.value = tabName;
};

const beforeRouteLeave = (to, from, next) => {
  if (dirty.value) {
    const confirmMessage = 'You have unsaved changes. Are you sure you want to leave?';
    if (confirm(confirmMessage)) {
      next();
    } else {
      next(false); // Prevent navigation
    }
  } else {
    next();
  }
};

import { onBeforeRouteLeave } from 'vue-router';
import parsePlaceholders from '../placeholders';
onBeforeRouteLeave(beforeRouteLeave);

const onLock = async () => {
  news.value.locked = news.value.locked ? undefined : { user: user, timestamp: Date.now() };
  await api.post('news', { news: news.value }, authHeaders());
};

const todayDatePlaceholder = () => '@date@=' + dayjs().format('MMM D, YYYY');

const onDeleteNews = async () => {
  api.delete('news/' + news.value.id, authHeaders());
  router.push('/news');
};

const onMdEditorSave = (v) => onSave();
const onMdEditorChange = (v) => dirty.value = v !== news.value.markdown;

const handleImageUpload = async (files, callback) => {
  try {
    const baseUrl = import.meta.env.VITE_API_URL;
    const headers = { 'Content-Type': 'multipart/form-data' , ...authHeaders().headers };

    const uploadPromises = files.map(async (file) => {
      const form = new FormData();
      form.append('image', file);
      const response =  api.post(`${baseUrl}news/uploadImages`, form, { headers });
      return response;
    });

    const imagePathResponses = await Promise.all(uploadPromises);

    const uploadedImageUrls = imagePathResponses.map(imagePathResponse => {
      const imagePath = imagePathResponse.data.imagePath;
      const imageUrl = `${import.meta.env.VITE_API_URL}${imagePath}`;
      return imageUrl;
    })

    callback(uploadedImageUrls);

  } catch (error) {
    console.error('Error uploading images:', error);
  }
};

const updatePlaceholders = () => {
  news.value.placeholders = parsePlaceholders(news.value.placeholdersString);
};

const onSave = async () => {
  console.log('onSave news.placeholders', news.placeholders)
  console.log('onSave news.placeholdersString', news.placeholdersString)
  news.last_modified = { user: { name: user.name, email: user.email }, timestamp: Date.now() };
  updatePlaceholders();
  news.value.markdown = markdown.value;
  dirty.value = false;
  await api.post('news', { news: news.value }, authHeaders());
};

// XXX: add debounce save on settings
// import { debounce } from 'lodash';
// const debouncedSave = debounce(onSave, 3000); // Debounce delay (in milliseconds) for save

onBeforeUnmount(() => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.removeEventListener('change', updateTheme);
});

function updateTheme(prefersDarkTheme) {
  mdEditorTheme.value = prefersDarkTheme.matches ? 'dark' : 'light';
}

</script>

<style scoped>
.accordion-sm .accordion-button {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.accordion-sm .accordion-collapse {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}

.text-left {
  height: 84vh;
}

pre.trace {
  white-space: pre-wrap;
  padding: 10px;
  background-color: #f0f0f0;
}

code.trace {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: normal;
  display: inline-block;
  word-break: break-all;
}</style>
